<template>
  <v-container fluid>
    <v-row>
      <v-col md="12" cols="12" class="d-flex justify-space-between">
        <div class="conOfHeader">
          <div class="headerOfPage">Tasks</div>
        </div>
        <div class="conOfHeader text-right pt-3">
          <div class="btnAdd">
            <v-btn
              class="btnAddPrimary"
              :disabled="isLoadingSave"
              :loading="isLoadingSave"
              @click="dialogTask = true"
            >
              <v-icon>mdi-plus</v-icon> Add Tasks
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-row>
        <v-col cols="12">
          <section class="conOfProjects">
            <div class="conOfTable">
              <v-data-table
                :headers="headers"
                :items="listData"
                hide-default-footer
              >
                <template v-slot:[`item.tags`]="{ item }">
                  <div v-for="tag in item.tags" :key="tag.name">
                    {{ tag.name }}
                  </div>
                </template>

                <!-- <template v-slot:[`item.tags`]="{ item }">
                  <span v-for="(tag, index) in item.tags" :key="tag.id">
                    {{ tag.name }}
                    <span v-if="index < item.tags.length - 1">, </span>
                  </span>
                </template> -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu bottom :left="true" :offset-y="true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <template>
                        <v-list-item @click="updateTask(item)">
                          <v-list-item-title class="statusTxt">
                            Edit
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </div>
          </section>
          <div class="conOfPagination pr-5 pt-5">
            <v-pagination
              v-model="pagination.current_page"
              :total-visible="7"
              :length="pagination.last_page"
              @input="goToPage(pagination.current_page)"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog v-model="dialogTask" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">ADD Task</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row class="conFormSubmit d-flex justify-center">
              <v-col cols="12">
                <label class="signinLable">Project Name</label>

                <v-select
                  outlined
                  v-model="formItem.project_id"
                  :items="projectList"
                  item-text="project_name"
                  item-value="id"
                  placeholder="project Name"
                  hide-details="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  attach
                  append-icon="mdi-chevron-down"
                >
                </v-select>
                <div>
                  <label class="eachLabel">Tsak Name</label>

                  <v-text-field
                    v-model="formItem.name"
                    outlined
                    item-text="name"
                    item-value="name"
                    placeholder="Task Name"
                    hide-details="auto"
                    attach
                  >
                  </v-text-field>
                </div>
                <div class="pt-3">
                  <label class="eachLabel">Tasks Description</label>

                  <v-textarea
                    v-model="formItem.description"
                    outlined
                    item-text="name"
                    item-value="id"
                    placeholder="Tasks Description"
                    hide-details="auto"
                  >
                  </v-textarea>
                </div>

                <div class="pt-3">
                  <label class="eachLabel">Tags</label>

                  <v-text-field
                    v-model="formItem.tags"
                    outlined
                    :items="tagList"
                    item-text="name"
                    item-value="id"
                    placeholder="Tags"
                    hide-details="auto"
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="dialogTask = false">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="addTask()"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditTask" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Edit Task</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row class="conFormSubmit d-flex justify-center">
              <v-col cols="12">
                <label class="signinLable">Project Name</label>

                <v-select
                  outlined
                  v-model="formItem.project_id"
                  :items="projectList"
                  item-text="project_name"
                  item-value="id"
                  placeholder="project Name"
                  hide-details="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  attach
                  append-icon="mdi-chevron-down"
                >
                </v-select>
                <div>
                  <label class="eachLabel">Tsak Name</label>

                  <v-text-field
                    v-model="formItem.name"
                    outlined
                    item-text="name"
                    item-value="name"
                    placeholder="Task Name"
                    hide-details="auto"
                    attach
                  >
                  </v-text-field>
                </div>
                <div class="pt-3">
                  <label class="eachLabel">Tasks Description</label>

                  <v-textarea
                    v-model="formItem.description"
                    outlined
                    item-text="name"
                    item-value="id"
                    placeholder="Tasks Description"
                    hide-details="auto"
                  >
                  </v-textarea>
                </div>

                <div class="pt-3">
                  <label class="eachLabel">Tags</label>

                  <v-text-field
                    v-model="formItem.tags"
                    outlined
                    item-text="name"
                    item-value="id"
                    placeholder="Tags"
                    hide-details="auto"
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="dialogEditTask = false">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="confirmEditTask()"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    formItem: {
      project_id: "",
      name: "",
      description: "",
      tags: [],
    },
    dialogTask: false,
    dialogEditTask: false,
    projectList: [],
    pagination: {
      current_page: 1,
    },
    headers: [
      { text: "Task Name", value: "name" },
      { text: "Task Description", value: "description" },
      { text: "Tags", value: "tags" },
      { text: "", value: "actions", sortable: false },
    ],
    isLoadingSave: false,
  }),
  computed: {},
  methods: {
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.tagList = [];

      apiServices.get(`tasks?per_page=7&page=${page}`).then((res) => {
        if (res) {
          this.isLoadingSearch = false;
          this.listData = res.data;
          console.log("res.data.tags", res.data.tags);

          this.tagList = res.data.tags;

          console.log("tagList", this.tagList);
          console.log("listData", this.listData);
          this.pagination = res.meta;
          console.log("pagination", this.pagination);

          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    addTask() {
      const errors = [];
      this.isLoadingSave = true;
      apiServices.post("tasks", this.formItem).then((res) => {
        if (res) {
          this.isLoadingSave = false;
          this.getListData();
          this.formItem = "";
          this.dialogTask = false;
        } else {
          this.isLoadingSave = false;
          return errors;
        }
      });
    },
    updateTask(item) {
      let editFormItem = {
        project_id: item.project_id,
        name: item.name,
        description: item.description,
        tags: item.tags,
        task_id: item.id,
      };
      this.formItem = editFormItem;
      this.dialogEditTask = true;
    },
    confirmEditTask() {
      const errors = [];
      this.isLoadingSave = true;
      apiServices.post("update/task", this.formItem).then((res) => {
        if (res) {
          this.isLoadingSave = false;
          this.getListData();
          this.dialogEditTask = false;
          this.formItem = "";
        } else {
          this.isLoadingSave = false;
          return errors;
        }
      });
    },
    getProjectList() {
      this.projectList = [];
      this.isLoadingUsersList = true;
      apiServices.get(`done/projects`).then((res) => {
        if (res) {
          this.projectList = res.data;
          this.isLoadingUsersList = false;
          this.dialogTask = false;
        } else this.isLoadingUsersList = false;
      });
    },
  },
  created() {
    this.getProjectList();
    this.getListData(this.pagination.current_page);
  },
  watch: {},
};
</script>
<style lang="scss" scoped></style>
